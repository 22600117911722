@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
  
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: 5px;
  scrollbar-color: #2b06ff;
  
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #000000;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
  visibility: hidden;
}

*:active::-webkit-scrollbar-thumb,
*:focus::-webkit-scrollbar-thumb,
*:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.products_wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.7rem;
}

.imagep{
height: 100%;
 object-fit: fit;
 
}
@media only screen and (max-width:639px) {
  .mobile{
   display: none;
  }
 




 
  .head-media{
   gap:4em;
  }
 }



 .spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 9px solid #4e57d4;
  animation: spinner-bulqg1 0.8s infinite linear alternate,
    spinner-oaa3wk 1.6s infinite linear;
}


@keyframes spinner-bulqg1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }

  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }

  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes spinner-oaa3wk {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}




.box {
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0;
  position: relative; /* Needed for absolute positioning of :after */
}
.box:after {
  content: "After Content";

 display: block;
  bottom: 0px; /* Adjust position as needed */
  right: 0px; /* Adjust position as needed */
  font-size: 14px;
  background: blue;
  z-index: 49;
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes divv {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}








/* styles.css */
@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.input-container {
  position: relative;
  display: inline-block;
  width: 60%;
}

.input-container input {
  width: 100%;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.1);
  font-size: 10px;
}



.button {
  background: linear-gradient(90deg, #fd5d93, #c331d4); /* Updated gradient colors */
  color: white;
  padding: 1px 8px 1px 8px;
  border: none;
  border-radius: 0.5rem;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button:hover {
  background: linear-gradient(90deg, #c331d4, #fd5d93); /* Updated gradient colors */
  transform: translateY(-2px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}


.containerone {
  position: relative;
  width: 100vw;
  max-height: 80vh;
  /* z-index: -1;
  position: sticky; */
}

.three-canvas {
  position: absolute;
  /* background-color: red; */
top: 0;
  /* bottom: 0; */
  left: 0;
  width: 100%;
  max-height: 80%;
}

.video-background {
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 75vh;
  object-fit: cover;
  /* overflow: hidden; */
  z-index: -1;
}

.video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  object-fit: cover;
  left: 50%;
  transform: translate(-50%, -50%);
}




.hint-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: moveLeftRight 2s infinite alternate;
}

@keyframes moveLeftRight {
  0% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(20px);
  }
}


.billing{
  font-size: 13px !important;
}


.scrollable-div {
  -webkit-overflow-scrolling: touch; /* Webkit browsers ke liye touch scrolling enable karna */
  scrollbar-width: none; /* Scrollbar ko hide karna */
  -ms-overflow-style: none; /* Internet Explorer ke liye scrollbar ko hide karna */
}



/* Whatsapp Icons */

.btn-pink {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  /* width: fit-content; */
  color: white;
  padding: 0.5rem;
  /* box-shadow: 5px 5px 7px 0px #ef476f3f; */
  font-size: 3rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s;
  font-weight: 500;
  border: solid 3px transparent;
  position: relative;
  z-index: 1;
  /* border-radius: 5px; */
}

.btn-pink::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.8s;
}

.btn-pink:hover::before {
  transform: scaleX(1);
}

.btn-pink:hover {
  border: solid 3px #115a15;
  /* color: green; */
  scale: 1.1;
} 

.no-scroll {
  position: fixed;
  width: 100vw;
  height: 100vh;
}











.swiper-container {
  width: 100%; /* Or adjust based on your layout */
  overflow: hidden; /* Ensure no unintended scrollbars */
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.invert {
  filter: invert(1);
}





.checkout-button {
  position: relative;
  width: 91.6667%; /* w-11/12 */
  background-color: #1a1a1a; /* bg-gray-900 */
  color: white;
  padding: 0.5rem; /* p-2 */
  margin-top: 0.75rem; /* mt-3 */
  border-radius: 1rem; /* rounded-xl */
  font-weight: bold;
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.checkout-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkout-button::before {
  content: "Checkout";
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  
  color: rgb(238, 238, 238);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: left 0.5s ease;
  font-weight: bold;
  border-radius: 1rem; /* To match the button's rounded corners */
}

.checkout-button:hover::before {
  left: 0;
}

.checkout-button:hover {
  transform: scale(1.05);
}







.rotating-text-container {
  position: absolute;
  width: 90px; /* Size of the circular path */
  height: 90px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: rotate-container 10s linear infinite;
}

.rotating-text {
  position: relative;
  width: 50%;
  height: 50%;
}

.circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0 0;
  white-space: nowrap;
  font-size: 10px; /* Adjust font size as needed */
  text-align: center;
  animation: rotate-text 10s linear infinite;
  color: white; /* Text color */
  text-shadow: 0 0 5px #00f, 0 0 10px #00f, 0 0 15px #00f, 0 0 20px #00f, 0 0 25px #00f, 0 0 30px #00f, 0 0 35px #00f; /* Glow effect */
}

.circle-text:nth-child(1) { transform: rotate(0deg) translate(0, -40px); }
.circle-text:nth-child(2) { transform: rotate(36deg) translate(0, -40px); }
.circle-text:nth-child(3) { transform: rotate(72deg) translate(0, -40px); }
.circle-text:nth-child(4) { transform: rotate(108deg) translate(0, -40px); }
.circle-text:nth-child(5) { transform: rotate(144deg) translate(0, -40px); }
.circle-text:nth-child(6) { transform: rotate(180deg) translate(0, -40px); }
.circle-text:nth-child(7) { transform: rotate(216deg) translate(0, -40px); }
.circle-text:nth-child(8) { transform: rotate(252deg) translate(0, -40px); }
.circle-text:nth-child(9) { transform: rotate(288deg) translate(0, -40px); }
.circle-text:nth-child(10) { transform: rotate(324deg) translate(0, -40px); }

@keyframes rotate-container {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}



.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.faq-title {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
}

.faq-category {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.faq-item {
  margin-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.faq-question {
  font-size: 1.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-toggle-icon {
  font-size: 1.5em;
  font-weight: bold;
}

.faq-answer {
  margin-top: 10px;
  font-size: 1em;
  color: #555;
}
