@keyframes shirt-float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .shirt-animation {
    animation: shirt-float 2s infinite;
  }
  